.rapport-page {
  .page-content {
    .section-2 {
      margin-top: 40px;

      .blc {
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        height: 323px;
        position: relative;
        -webkit-box-shadow: -3px 1px 25px 1px #00000014;
        -moz-box-shadow: -3px 1px 25px 1px #00000014;
        box-shadow: -3px 1px 25px 1px #00000014;

        .icon {
          background-color: gray;
          height: 63px;
          width: 70px;
          border-radius: 20px;
          margin-bottom: 14px;
          background-size: cover;
          background-position: center;
          padding: 20px;
        }

        .title {
          font-weight: 600;
          font-size: 18px;
          margin-top: 16px;
        }

        .subtitle {
          color: gray;
          margin-top: 8px;
        }

        .note {
          text-align: center;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 700;
          position: absolute;
          bottom: 56px;
          left: 50%;
          transform: translateX(-50%);
        }

        .btn-up {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }

        .up {
          background-color: #CAECD6;
          border: 2px solid #31AD51;
          border-radius: 5px;
          display: inline-table;
          padding: 2px 12px;
          text-align: center;
          color: #31AD51;
          font-weight: 500;
        }

        .btn-down {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }

        .down {
          background-color: #f001012b;
          border: 2px solid red;
          border-radius: 5px;
          display: inline-table;
          padding: 2px 12px;
          text-align: center;
          color: red;
          font-weight: 500;
        }
      }

      .blc-second {
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        height: 140px;
        position: relative;
        -webkit-box-shadow: -3px 1px 25px 1px #00000014;
        -moz-box-shadow: -3px 1px 25px 1px #00000014;
        box-shadow: -3px 1px 25px 1px #00000014;

        .title {
          font-weight: 600;
          font-size: 18px;
        }

        .subtitle {
          color: gray;
          margin-top: 8px;
        }

        .btn-up {
          position: absolute;
          bottom: 20px;
          font-size: 30px;
          font-weight: 600;
          color: #F66766;
        }
      }
    }

    .section-3 {
      margin-top: 60px;

      .title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 18px;
        color: #5B6E8C;
      }

      .chart {
        background-color: white;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid #80808021;
      }
    }
  }
}